import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MyLogoFixed from "../components/LogoFixed"
import MySignature from "../images/Signature.svg"
import RSL from "../Audio/RSL.mp3"

import "../Style/EssayPage.css"
import TitleUnderlined from "../components/TitleUnderlined"

const RSH = ({data}) => (
 <div> 

    <SEO 
    title="On Racism, Sexism and LGBT+ by Phil Murphy"
    />

    <Helmet>

        <meta name="robots" content="noindex" />
        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="My take on Racism, Sexism, and LGBT+ and why I think social justice is important, but some people get it wrong">
        </meta>

        <meta name="og:description" content="My take on Racism, Sexism, and LGBT+ and why I think social justice is important, but some people get it wrong">
        </meta>

        <meta name="description" content="My take on Racism, Sexism, and LGBT+ and why I think social justice is important, but some people get it wrong">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="My take on Racism, Sexism, and LGBT+ and why I think social justice is important, but some people get it wrong">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>



    


    <div className="EssayPageWrapper">

        <essayheader><MyLogoFixed /></essayheader>

        <essaycontent>


        <p className="EssayTitlePage">On Racism, Sexism, and LGBT+</p>



            <div className="EssayAddContentPage"><span className="TextHighlight">Don't have time to read?</span> You can listen to this memo just like a normal podcast 😎</div>

              <audio preload="auto" controls className="AudioPlayer">
                  <source src={RSL} type="audio/mpeg" />
                  <p className="EssayAddContentPage">Your browser does not support the audio element :(</p>
              </audio>

            <div className="Contentnderline"></div>

          


        <div className="P_style">

        <p>Let's face it, in the modern world, you hear about those things so often you can't run away from them.</p>

        <p>They are entangled, complicated, and hard. </p>
          
        <p>But most importantly — misunderstood.</p>

        <p>For most people, it's even scary and uncomfortable to talk about them. Worst, a lot of people feel tension, so they start to confront and reject it. In fact, I was one of those people.</p>

        <p>But what's the real obstacle? Why most of the time world fall into 2 major radical clusters: <b>haters</b> or <b>supporters</b>?</p>

        <p>First of all, I will not take one side or another. One of the biggest problems is that people either aid or abhor. But it enormously harder than that. It's not always cruel. Sometimes people abuse it. Sometimes it's socially forced. Sometimes it's a real problem.</p>

        <p>People must see these things through the lines. People need to know when something seems ridiculous is actually the right thing to do, as well as, people need to know when something is moving too far.</p>

        <p>Second, I believe there is a huge gap between what people think and what it's really meant to be racist, sexist, or homophobic. Robin DiAngelo famously described in her book White Fragility the phenomenon of how difficult it is for a white person to really understand the essence of the problem due to misconceptions and lack of authentic information.</p>

        <p>But I don't think it makes sense to blindly support DiAngelo's take either.</p>

        <p>It's not a secret. We live in a world where we have a political spectrum that determines which values are more relevant to us individually and socially.</p>

        <p>Rightists and leftists have the right to stick to their positions and follow what they believe is best for the world. But we don't live in an ideal society. Every time when people start talking about civil rights, whether because of the massive protests or just a random burst, instead of real changes, there is a war of those exact clusters: <b>haters</b> and <b>supporters</b>.</p>
        
        <p><span className="TextHighlight">The war for who is right. And not for actual social justice.</span></p>

        <p>And now we are at the most important point. — No matter what political views you hold, no matter where you live or what cultural values you profess. It's incredibly important to understand what is wrong and what is beneficial from both sides in order to find the truth.</p>

        <p>Let me start off from a left-wing position for one simple reason. This is what we see with our own eyes and what we hear every time something is going on. This is what some mass media tell us and what numerous famous people promote in Western society.</p>

        <p>Leftism implies a struggle for civil rights through active actions, let's say, protests for equality. And in light of all the recent events, the most common ideas have been "Whiteness" and "White Fragility", the one I mentioned earlier.</p> 
          
        <p>The idea is quite simple: the majority of institutions are controlled by white people, perhaps even to a greater extent by white men, and they continue trying to hold the position of a white in relation to other races. Thus, the key prop that is used has been the white people are obligated to use their privilege in order to make a change. A white person is obligated to help promote minority voices, and actively participate in integrating this idea in various places, such as workplaces and so on.</p>

        <p>In other words, to achieve equality, after years of inequality, it's necessary to exalt minorities and help them by putting them above white since only a white can make a real change (e.g. decisions what changes should happen have always been decided and continue to be decided mostly by whites, hence whites must take the high road if they want change to occur).</p>

        <br />

        <p>Well, what about right-wings? Rightism can be described as those who simply deny everything that left-wings wants, without offering something in exchange. — And don't get me wrong, I may have missed some of the ideas, but "Rightism" in most cases is a simple objection and criticism out of fear that all left-wings' actions could cause more damage than goods. And I see several reasons why.</p>

        <p><i>But first: let's try to figure out what rightists are standing for; I honestly tried to find as many diverse opinions on this subject as possible, and this is what I came up with:</i></p>

        <br />

        <p><b>— Right-wingers mostly do not deny the issue of human/social rights, but very cautious in terms of the theory of "Whiteness" and "White Fragility" as a whole.</b></p>

        <p><b>— Speaking of "White Fragility", one of the ideologies is based on the fact that it only divides the races even more and it acts like reverse racism and oppression.</b></p>

        <div className="QuoteBlockPurple">
          <div className="QuoteContent"><span className="QuoteHighlight">"Interestingly, many of the emotions DiAngelo cites as evidence of white fragility—such as anger, shame, guilt, and apathy—are listed by Hawkins as a reaction to force. Nowhere in white fragility theory or whiteness studies can one find positive responses related to true power, such as courage, love, joy, or enlightenment; everything tied to white fragility is zero-sum and is based on dichotomy rather than unity."</span></div>
          <p className="QuoteOwner">— <a href="https://thefederalist.com/2020/02/28/how-white-fragility-theory-turns-classrooms-into-race-charged-power-struggles/" target="_blank" rel="noopener"><b>Jonathan Church and Chris Paslay</b></a></p>
        </div>

        <p><b>— Rightists believe that leftist activists are only hiding behind the fact that they are driven by moral principles and they want to help. In fact, they only do more harm, using events for their hostile attitude and desire to have power, thereby starting a racial hatred.</b></p>

        <div className="QuoteBlockPurple">
          <div className="QuoteContent"><span className="QuoteHighlight">"Leftists may claim that their activism is motivated by compassion or by moral principles, and moral principle does play a role for the leftist of the oversocialized type. But compassion and moral principle cannot be the main motives for leftist activism. <br/> <br/> Hostility is too prominent a component of leftist behavior; so is the drive for power. Moreover, much leftist behavior is not rationally calculated to be of benefit to the people whom the leftists claim to be trying to help. For example, if one believes that affirmative action is good for black people, does it make sense to demand affirmative action in hostile or dogmatic terms? Obviously it would be more productive to take a diplomatic and conciliatory approach that would make at least verbal and symbolic concessions to white people who think that affirmative action discriminates against them. <br/> <br/> But leftist activists do not take such an approach because it would not satisfy their emotional needs. Helping black people is not their real goal. Instead, race problems serve as an excuse for them to express their own hostility and frustrated need for power. In doing so they actually harm black people, because the activists’ hostile attitude toward the white majority tends to intensify race hatred."</span></div>
          <p className="QuoteOwner">— <a href="https://www.washingtonpost.com/wp-srv/national/longterm/unabomber/manifesto.text.htm" target="_blank" rel="noopener"><b>The Unabomber Manifesto (1995)</b></a></p>
        </div>

        <p>But does every rightist really follow all these principles?</p>

        <p>Like everything about collective parties, I'd say no. However, I'd like to point out that although many people may have different opinions within their inner circles, there are a large number of people who may never even have thought about what they believe in.</p> 
          
        <p>Being leftist or rightist is not a choice, it's a reflection of your actions. Therefore, many who have never thought and thinking they are not really any of them, behave like one after all.</p>

        <p>And the thing is, there is a huge gap in essential knowledge among these groups. Many simply don't want to educate themselves about racism or sexism. Thus, every time when events, where the leftists' activists manifest themselves comes up, they all begin to exaggerate every action, and hundreds of important events towards civil rights begin to overlap with isolated cases of "something bad", maybe not even related to activists' actions themselves.</p>

     

        <p><i><span className="TextHighlight">"A good portion of people go crazy and try to stop everything, not because it goes against their true beliefs, but because they don't know what to believe, so they feel disgusted and offended."</span></i></p>

        <div className="Contentnderline"></div>

        <div className="EssaySecondHeaderTypeRed">So what can all this cause?</div>

        <p><i>Among both sides, I see 2 main ways that can lead to disaster:</i></p>

        <TitleUnderlined title="Rejection Turning Into Open Hatred" color="red" />

        <p>Many are convinced the problem is overblown. And everything is more or less, but functioning normally. Thus, any manifestation of initiative towards change begins to be perceived as questionable, and if this begins to happen on a daily basis, such as massive protests, it begins to develop into open hatred.</p>

        <p>Those "many" also believe that everything should be peaceful, and they are not themselves racist or sexist, as well as that different people should be equal and they don't care what color your skin is or what your gender is.</p>

        <p>But as soon as the demands for change begin to occur, or the changes themselves, the majority abruptly begins to hate and despise, inadvertently turning into someone whom they don't consider themselves — i.e. racists, sexists, and homophobes.</p>

        <p>This happens for the reason I described earlier. They don't believe there are problems. And any manifestation of a desire to change "something" just feels like an exaggeration and abuse.</p>

        <p>One of the ideas of "White Fragility" though is that such people have implicit bias and that it's necessary to arrange training to control it. However, <a href="https://qz.com/1144504/the-world-is-relying-on-a-flawed-psychological-test-to-fight-racism/" target="_blank" rel="noopener">research</a> shows that it's almost impossible to attach bias to the fact that someone might be a racist. Instead, it offers a simple but sincere theory that people are actually racist, and it's just hard for them to admit it. — We all know that it's bad to be described by one of these terms, hence many are afraid to take responsibility for who they really are.</p>

        <p>In the end, this leads to the fact that in tense moments, many who did not consider themselves such, show their true intentions.</p>

        <br />

        <p>The same thing happens on the other side, though. People who support the theory of "White Fragility" can be very cruel and contemptuous of those who question this concept. Often, even the slightest doubt on their side automatically makes someone who tries to be not rational — evil.</p>

        <p>Consequently, leftists like to use the <a href="https://medium.com/@MatthewQKnipfer/whats-a-kafkatrap-29a1026225c" target="_blank" rel="noopener">Kafka trap</a>, the one when any denial equals guilty, to always point out that their methods are totally legit, and you definitely a racist since you try to deny it or get to know it better.</p>

        <br />

        <p>And yet, this issue can only be solved if people begin self-learning. The problem grows only from ignorance and the absence of important information from both sides.</p>
          
        <p>People don't give a shit about finding ways to unite. People care about finding ways to keep the war going.</p>

        <br />

        <TitleUnderlined title="Exaggeration" color="red" />

        <p>Another problem is widespread exaggeration.</p>

        <p>Changes and advocating for human rights is important, as long as it does not break the absurd's limits.</p>
        
        <p>Although, leftists and rightists have various opinions on what is an exaggeration and what limits are. Worse, people within these groups also define it differently.</p>

        <p>People like to use one bad example to block out thousands of good ones. And it's getting worse and worse.</p>

        <p>In the struggle for who is right, people start using arguments that might be completely irrelevant to the context. Maybe even the opposite. — Trying to call each other's actions foolish and hypocritical, people don't see that, in fact, their opinions don't go so far from each other.</p>

        <p>People like to exaggerate abuse when they see news like a white voice actor deciding to give his slot to someone more suitable.</p>

        <div className="EssayTwitterImagePage">
          <a href="https://twitter.com/mikehenrybro/status/1276631712949317639" target="_blank" rel="noopener">
            <Img 
                fluid={data.Tweet1.childImageSharp.fluid}
            />
          </a>
        </div>

        <p>But no one notices using such examples that many don't support this solution either. Thinking this is really too much. And it doesn't make sense.</p>

        <div className="EssayTwitterImagePage">
          <a href="https://twitter.com/VoicemanWilson/status/1276635668832309248" target="_blank" rel="noopener">
            <Img 
                fluid={data.Tweet2.childImageSharp.fluid}
            />
          </a>
        </div>

            <br /> <br />

        <p><span className="QuoteHighlight">Uncertainty gives rise to disputes which just more divide people. And it feels like the most serious problem out there.</span></p>

        <br />

        
        <TitleUnderlined title="In sum" color="green" />
        

        <p>Civil and human rights are important. </p>
          
        <p>So I remain neutral with a slight left-of-center move and keep self-learning towards understanding the problem better. At the very least, I believe this is what is necessary for everyone to ensure that this does not turn into something more dangerous.</p>

        <p>And like <a href="https://twitter.com/spakhm" target="_blank" rel="noopener">Slava Akhmechet</a> said lately: <a href="https://www.defmacro.org/2020/06/02/future.html" target="_blank" rel="noopener">"Nobody knows the future."</a> — No one knows what events and which side will lead to disaster, and which to the prosperity of the world. We need to be careful and meaningfully evaluate all actions that occur no matter whom you stand for.</p>

        <p>After all, I urge everyone just to be reasonable. Not everything is so obvious and it takes time and self-learning to get better.</p>



        </div>


        </essaycontent>







        <essayfooter>
        <div>
          <div className="Signature"><img src={MySignature} width="166" /></div>
        </div>
        </essayfooter>

    </div>

    </div>
)

export default RSH

export const query = graphql`
  query {
  
      Tweet1: file(relativePath: { eq: "Mike.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

      Tweet2: file(relativePath: { eq: "Wilson.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        MyCoverDefault: file(relativePath: { eq: "PhilMurphy_CoverDefault.png" }) {
          childImageSharp {
            fluid(maxWidth: 6000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

  }
`